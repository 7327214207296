<template>
  <div class="pvhFactory">
    <basic-container>
      <div class="layTop">
        <div class="Title" style="margin-bottom:10px;">{{$t('Operation Log')}}</div>
      </div>
      <div class="layTop" style="display:none">
        <el-button type="primary" size="small" plain @click="searchoperation">{{$t('Operation Log')}}</el-button>
        <el-button type="primary" size="small" plain @click="searchapi">{{$t('Api Log')}}</el-button>
        <el-button type="primary" size="small" plain @click="searchaudit">{{$t('Audit Log')}}</el-button>
      </div>
      <template>
        <el-row :gutter="20" class="topFromSearch">
          <el-col :xs="24" :sm="17" :md="17" :lg="19" :xl="20" class="fromOutDiv">
            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="display:none">
              <span>{{$t('Source Module')}}:</span>
              <el-input v-model="sourceVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
            </el-col>
            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <span>{{$t('BEINGTIME')}}:</span>
              <el-date-picker class="dataicon" v-model.trim="beginTimeVal"
                              type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
              </el-date-picker>
            </el-col>
            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <span>{{$t('ENDTIME')}}:</span>
              <el-date-picker class="dataicon" v-model.trim="endTimeVal"
                              type="date" @focus="focusDiv($event)" @blur="blurDiv($event)" prefix-icon="" style="width:100%;">
              </el-date-picker>
            </el-col>
            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6" style="display:none">
              <span id="venSpan">{{$t('FROM')}}:</span>
              <el-select v-model="userId" @focus="focusDiv($event)" @blur="blurDiv($event)" collapse-tags placeholder="" style="width:100%;">
                <el-option v-for="item in this.fromData"
                           :key="item.userId"
                           :label="item.name"
                           :value="item.userId">
                </el-option>
              </el-select>
            </el-col>
          </el-col>
          <el-col :xs="24" :sm="7" :md="7" :lg="5" :xl="4" style="padding-top: 25px; text-align: right; align-self: flex-end;">
              <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="search">  {{$t('Search')}}</el-button>
          </el-col>
        </el-row>
        <avue-crud class="pvhTable"
                   v-model="obj"
                   :option="setData.tableOpt"
                   :data="tableData"
                   :row-style="rowStyle"
                   :page.sync="page"
                   @current-change="currentChange">

          <template slot-scope="{row,index,size,type}" slot="menu">
            <el-button icon="el-icon-s-order" type="text" size="medium" :title="$t('DetailedInfo')" @click="LogDetailinfo(row,index,size,type)"></el-button>
          </template>

        </avue-crud>

        <el-dialog :title="$t('DetailedInfo')" :visible.sync="LogDetaildialogTableVisible" :modal-append-to-body="false" width="70%">
          <el-form :model="LogDetailEdit" label-position="top" label-width="200px" label-height="200px">
            <el-row>
              <el-col>
                <el-form-item>
                  <textarea ref="textarea"  v-model="LogDetailEdit.Action" style="height: 200px; width: 800px;"></textarea>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-dialog>
      </template>
    </basic-container>

  </div>
</template>
<style>
.el-dialog__wrapper {
  transition-duration: 0.3s;
}

.othChe .el-checkbox__label {
  color: #409eff !important;
}

.dataicon i {
  display: none;
}

.my-textarea .textarea {
  display: inline-block;
  width: 400px;
  /*height: 30px;*/
  line-height: 200px;
  font-size: 30px;
  resize: none;
}
</style>
<script>
import { operateLogList, userList } from "@/api/logList";

export default {
  data() {
    return {
      obj: {},
      LogDetaildialogTableVisible: false,
      LogDetailEdit: {
        Action: ""
      },
      userId: "",
      sourceVal: "",
      beginTimeVal: "",
      endTimeVal: "",
      tableData: [],
      fromData: [],//用户
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      }
    }
  },
  computed: {
    setData() {
      return {
        tableOpt: {
          page: true,
          addBtn: false,
          refreshBtn: false,
          emptyText: this.$t('No Data'),
          menu: true,
          menuTitle: this.$t('OPERATION'),
          menuWidth: 140,
          editBtn: false,
          editBtnText: this.$t('Edit'),
          editTitle: this.$t('Edit'),
          delBtn: false,
          align: 'center',
          header: false,
          simplePage: false,
          labelWidth: "33%",
          expand: false,
          rowKey: 'Id',
          expandRowKeys: [],
          column: [
            {
              label: this.$t('Source Module'),
              prop: 'source',
            },
            {
              label: this.$t('OPERATOR'),
              prop: 'subjectName',
              editDisabled: true
            },
            {
              label: this.$t('CREATION TIME'),
              prop: 'created',
              type: "date",
              format: 'yyyy-MM-dd HH:mm:ss',
              valueFormat: 'yyyy-MM-dd HH:mm:ss',
              editDisabled: true
            }
          ]
        }
      }
    }
  },
  created() {
    this.getOperateLogList();
    userList('FromList').then(res => {
      this.fromData = res.data;
    });
  },
  methods: {
    //设置表头行的样式
    tableHeaderColor() {
      return 'background-color:#838bb3!important;padding: 0!important;'

    }, //表格行央视
    rowStyle({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },
    focusDiv(obj) {//搜索框获取焦点
      if (obj.currentTarget === undefined) {
        obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      } else {
        if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
          obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
        } else {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
        }

      }
    },//搜索框失去焦点
    blurDiv(obj) {
      if (obj.currentTarget === undefined) {
        if (obj.value === "") {
          obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else if (obj.currentTarget === null) {
        if (this.userId == null || this.userId.length === 0) {
          document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else {
        if (obj.target.value == "") {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      }
    },
    search() {
      this.getOperateLogList();
    },
    searchapi() {
      this.$router.push({ path: '/pvh_admin/apiLogList', params: { page: 1, pageSize: 10, module: "", apiName: "", beginTime: "", endTime: "" } });
    },
    searchaudit() {
      this.$router.push({ path: '/pvh_admin/auditLogList', query: { page: 1, pageSize: 10, module: "", user: "", beginTime: "", endTime: "" } });
    },
    searchoperation() {
      this.$router.push({ path: '/pvh_admin/logList', query: { page: 1, pageSize: 10, module: "", beginTime: "", endTime: "" } });
    },
    currentChange(val) {
      this.page.currentPage = val
      this.getOperateLogList();
    },
    async getOperateLogList() {
      await operateLogList(this.page.currentPage, this.page.pageSize, this.sourceVal, this.beginTimeVal, this.endTimeVal).then(res => {
        this.tableData = res.data.auditLogs;
        this.page.total = res.data.totalCount;
        this.page.pageSize = res.data.pageSize;
      }).catch(erro => {
        console.log(erro)
      })
    },
    LogDetailinfo(row) {
      this.LogDetaildialogTableVisible = true;
      this.LogDetailEdit.Action = row.action;

    },
  }
}
</script>

